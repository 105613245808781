<template>
  <div id="Project--detail-container">
    ProjectShowEditMode Page
  </div>
</template>

<script>

export default {
  name: "project",
  data() {
    return {
      currentProject: null,
      message: '',
    };
  },
  components: {
  },
  methods: {
  },
  mounted() {
  },
  updated: function(){
  }
};
</script>

<style scoped>
</style>